<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data">
            <template #departmentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="item.field.label"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :query="{
                      company_id: data.companyId,
                    }"
                    :ref="item.field.key"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>

            <template #jobDetailsEn>
              <div class="pt-2">
                <b-row>
                  <b-col
                    lg="12"
                    md="4"
                    v-for="(detailEn, idx) in data.jobDetailsEn"
                    :key="`detailEn-${idx}`"
                  >
                    <b-row>
                      <b-col cols="6">
                        <validation-provider
                          #default="{ errors }"
                          :vid="`${detailEn.title}-${idx}`"
                          :name="$t('field.titleEn')"
                          rules="required"
                        >
                          <label>{{ $t("field.titleEn") }}</label>
                          <b-form-input
                            :placeholder="$t('field.titleEn')"
                            autocomplete="off"
                            v-model="detailEn.title"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" class="mt-2">
                        <validation-provider
                          #default="{ errors }"
                          :vid="`${detailEn.description}-${idx}`"
                          :name="$t('field.descriptionEn')"
                          rules="required"
                        >
                          <label>{{ $t("field.descriptionEn") }}</label>
                          <ckeditor
                            tag-name="textarea"
                            v-model="detailEn.description"
                            :name="detailEn.description"
                            :config="detailEn.config"
                            :class="errors.length > 0 ? 'ck-invalid' : null"
                          ></ckeditor>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" align-self="end" class="text-right">
                        <b-button
                          variant="outline-danger"
                          class="mb-2 mt-2"
                          @click="removeDetailEn(idx)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-1"
                          ></feather-icon>
                          <span>{{ $t("button.delete") }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                    <hr class="mb-2" />
                  </b-col>

                  <b-col>
                    <b-button
                      variant="primary"
                      class="mb-2"
                      @click="addMoreDetailEn"
                    >
                      <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                      <span>{{ $t("button.addDetail") }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>

            <template #jobDetailsKm>
              <div class="pt-2">
                <b-row>
                  <b-col
                    lg="12"
                    md="4"
                    v-for="(detailKm, idx) in data.jobDetailsKm"
                    :key="`detailKm-${idx}`"
                  >
                    <b-row>
                      <b-col cols="6">
                        <validation-provider
                          #default="{ errors }"
                          :vid="`${detailKm.title}-${idx}`"
                          :name="$t('field.titleKm')"
                          rules="required"
                        >
                          <label>{{ $t("field.titleKm") }} </label>
                          <b-form-input
                            :placeholder="$t('field.titleKm')"
                            autocomplete="off"
                            v-model="detailKm.title"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" class="mt-2">
                        <validation-provider
                          #default="{ errors }"
                          :vid="`${detailKm.description}-${idx}`"
                          :name="$t('field.descriptionKm')"
                          rules="required"
                        >
                          <label>{{ $t("field.descriptionKm") }}</label>
                          <ckeditor
                            tag-name="textarea"
                            v-model="detailKm.description"
                            :name="detailKm.description"
                            :config="detailKm.config"
                            :class="errors.length > 0 ? 'ck-invalid' : null"
                          ></ckeditor>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" align-self="end" class="text-right">
                        <b-button
                          variant="outline-danger"
                          class="mb-2 mt-2"
                          @click="removeDetailKm(idx)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-1"
                          ></feather-icon>
                          <span>{{ $t("button.delete") }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                    <hr class="mb-2" />
                  </b-col>

                  <b-col>
                    <b-button
                      variant="primary"
                      class="mb-2"
                      @click="addMoreDetailKm"
                    >
                      <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                      <span>{{ $t("button.addDetail") }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                v-if="$can('create', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>
      
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import ckeditorConfig from "@/libs/ckeditorconfig";

const JobRepository = Repository.get("job");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    NAsyncSingleSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        companyId: null,
        departmentId: null,
        nameEn: null,
        nameKm: null,
        availablePost: null,
        sequenceOrder: null,
        isEnable: true,
        jobContents: [],
        metaTitleEn: null,
        metaDescriptionEn: null,
        metaTitleKm: null,
        metaDescriptionKm: null,
        jobDetailsEn: [],
        jobDetailsKm: [],
      },
      loading: false,
    };
  },
  watch: {
    "data.companyId"(newValue, oldValue) {
      if (oldValue && newValue != oldValue) {
        this.$refs.departmentId.clear();
      }
    },
  },
  methods: {
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;

      this.data.jobDetailsEn.forEach((detailEn) => {
        detailEn.locale = "en";
      });

      this.data.jobDetailsKm.forEach((detailEn) => {
        detailEn.locale = "km";
      });

      this.data.jobContents.push(
        {
          locale: "en",
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
          jobDetails: this.data.jobDetailsEn,
        },
        {
          locale: "km",
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
          jobDetails: this.data.jobDetailsKm,
        }
      );

      JobRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    addMoreDetailEn() {
      let detail = {
        locale: null,
        title: null,
        description: "",
        config: { ...ckeditorConfig.editorConfig },
      };
      this.data.jobDetailsEn.push(detail);
    },
    removeDetailEn(index) {
      this.data.jobDetailsEn.splice(index, 1);
    },
    addMoreDetailKm() {
      let detail = {
        locale: null,
        title: null,
        description: "",
        config: { ...ckeditorConfig.editorConfig },
      };
      this.data.jobDetailsKm.push(detail);
    },
    removeDetailKm(index) {
      this.data.jobDetailsKm.splice(index, 1);
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "job";
    const route = "job";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>