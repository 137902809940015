export default [
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "asyn-single-selection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    key: "departmentId",
    label: "field.department",
    rules: "required",
    type: "asyn-single-selection",
    repository: "department",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "availablePost",
    label: "field.availablePost",
    rules: "required|integer|min_value:1",
    type: "currency",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer|min_value:1",
    type: "currency",
  },
  {
    label: "breadcrumb.jobContentEn",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.jobDetailEn",
    icon: "ClipboardIcon",
    type: "divider",
  },
  {
    key: "jobDetailsEn",
    hideLabel: true,
    cols: 12,
  },
  {
    label: "breadcrumb.jobContentKm",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.jobDetailKm",
    icon: "ClipboardIcon",
    type: "divider",
  },
  {
    key: "jobDetailsKm",
    hideLabel: true,
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
